<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="12" v-show="!this.$route.params.document">
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :qTotal="Totals"
          :qDelete="Qdelete"
          :PageResponse="'cautions'"
          :AllResponse="'allcautions'"
          :TotalResponse="'totals_caution'"
          :title="'Cautions'"
          :headers="headers"
          :field="'DATE_CAUTION'"
          :filename="'cautions'"
          :sheetname="'cautions'"
          @open="OpenCautionForm"
          @rowselect="CautionChange"
          @delete="CautionDelete"
          :del_disable="
            caution.statut_id == '17' ||
            caution.nbdocs > 0 ||
            detail_list.length > 0
          "
          :showedit="false"
          :TypeScope="tiertype"
          :key="klist"
          :Add="editer"
          :del="editer"
          :search_elm="filtre"
        >
        </listitemspage>
      </v-col>
      <v-col cols="12" sm="4" md="8">
        <listitems
          :list="detail_list"
          :title="caution.ref_ca ? 'Caution N° : ' + caution.ref_ca : ''"
          :headers="detailheaders"
          :qDelete="Qdetaildelete"
          :master="false"
          :Total="true"
          :key="aff"
          :add="liberer && caution.statut_id == '17' && caution.reste > 0"
          :del="liberer"
          :showedit="false"
          @open="OpenDetailForm"
          @delete="refresh"
        >
        </listitems>
      </v-col>
    </v-row>

    <cautionform
      :item="caution"
      :key="kf"
      :tiertype="tiertype"
      :showForm="!isCautionClosed"
      @close="closeCautionForm"
      :cautions_type="cautions_type"
      :agences="agences"
      :cmds_list="cmds_list"
      :currencies="currencies"
      :editer="editer"
      :valider="valider"
      :liberer="liberer"
    >
    </cautionform>
    <cautiondetailform
      :item="cautiondetail"
      :items="detail_list"
      :caution="caution"
      :key="dkey"
      :showForm="!isDetailClosed"
      :readonly="!liberer"
      @close="closeDetailForm"
    >
    </cautiondetailform>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import CAUTIONS from "../graphql/Caution/CAUTIONS.gql";
import ALLCAUTIONS from "../graphql/Caution/ALLCAUTIONS.gql";
import DATA from "../graphql/Caution/DATA.gql";
import ACTUAL_CMDS from "../graphql/Mouvment/ACTUAL_CMDS.gql";
import DELETE_CAUTION from "../graphql/Caution/DELETE_CAUTION.gql";
import DELETE_CAUTION_DETAIL from "../graphql/Caution/DELETE_CAUTION_DETAIL.gql";
import TOTALS from "../graphql/Caution/TOTALS.gql";

export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    listitems: () => import("../components/ListItems.vue"),
    cautionform: () => import("../components/CautionForm.vue"),
    cautiondetailform: () => import("../components/CautionDetailForm.vue"),
  },
  props: {
    tiertype: Number,
  },
  data: () => ({
    filtre: null,
    klist: 0,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    loading: false,
    editer: false,
    valider: false,
    liberer: false,
    isDetailClosed: true,
    selitem: {},
    aff: 0,
    kf: 100,
    dkey: 200,
    headers: [
      {
        text: "N° Caution",
        slot: "href",
        value: "ref_ca",
        enum: "REF_CA",
        selected: true,
        totlib: true,
      },

      {
        text: "Type de caution",
        value: "type_caution",
        enum: "TYPE_CAUTION",
        selected: true,
      },
      {
        text: "Date Caution",
        value: "date_caution",
        enum: "DATE_CAUTION",
        slot: "date",
        selected: false,
        width: "110",
      },
      {
        text: "Validité",
        value: "date_validite",
        enum: "DATE_VALIDITE",
        slot: "date",
        selected: true,
        width: "110",
      },
      {
        text: "ML Init. Prev ",
        value: "date_lib_ini",
        enum: "DATE_LIB_INI",
        slot: "date",
        selected: false,
        width: "110",
      },

      {
        text: "ML Prev",
        value: "date_lib",
        enum: "DATE_LIB",
        slot: "date",
        selected: true,
        width: "110",
      },

      {
        text: "Tier",
        align: "text-left",
        value: "tier_name",
        enum: "TIER_NAME",
        selected: true,
      },

      {
        text: "Ref. Contrat",
        align: "text-left",
        value: "cmd_ref",
        enum: "CMD_REF",
        selected: true,
      },
      {
        text: "Montant ",
        value: "montant",
        enum: "MONTANT",
        slot: "cur",
        selected: true,
        align: "end",
        result: "tot_cautions",
        column: "reste",
        valeur: "0",
        width: "150",
      },
      {
        text: "Liberé ",
        value: "ttc_det",
        enum: "TTC_DET",
        slot: "cur",
        selected: true,
        result: "tot_ttc_det",
        align: "end",
        width: "150",
      },
      {
        text: "Reste ",
        value: "reste",
        enum: "RESTE",
        slot: "cur",
        selected: true,
        result: "tot_reste",
        align: "end",
        width: "150",
      },
      {
        text: "Monnaie",
        align: "text-left",
        value: "devise",
        enum: "DEVISE",
        selected: true,
      },
      {
        text: "Frais ",
        value: "frais",
        enum: "FRAIS",
        slot: "cur",
        selected: true,
        result: "tot_frais",
        align: "end",
        hiden: true,
        width: "150",
      },
      {
        text: "Banque",
        value: "agence",
        enum: "AGENCE",
        align: "text-center",
        selected: true,
      },
      {
        text: "Statut",
        value: "statut_name",
        enum: "STATUT_NAME",
        align: "text-center",
        selected: true,
      },
    ],
    detailheaders: [
      {
        text: "N°",
        value: "no",
        selected: true,
      },
      {
        text: "Opération",
        value: "operation",
        totlib: true,
        selected: true,
      },
      {
        text: "Ref.",
        value: "ref_doc",
        slot: "href",
        selected: true,
      },
      {
        text: "Date",
        value: "date_opr",
        slot: "date",
        selected: true,
      },

      {
        text: "Montant",
        value: "montant",
        slot: "cur",
        align: "end",
        total: "montant",
        selected: true,
      },

      {
        text: "Commentaire",
        value: "comment",
        selected: true,
      },
    ],
    isCautionClosed: true,
    caution: {},
    cautiondetail: {},
    changed: false,
    cmds_list: [],
    agences: [],
    cautions_type: [],
    currencies: [],
  }),

  computed: {
    Qdetaildelete() {
      return DELETE_CAUTION_DETAIL;
    },
    Qselect() {
      return CAUTIONS;
    },
    Qselectall() {
      return ALLCAUTIONS;
    },
    Qdelete() {
      return DELETE_CAUTION;
    },
    Totals() {
      return TOTALS;
    },
    detail_list: function () {
      let list = [];
      if (this.caution.id > 0) {
        list = this.caution.cautiondetails;
      }
      return list;
    },
  },
  watch: {
    tiertype: {
      handler() {
        this.aff++;
        this.caution = {};
        this.get_data();
      },
      deep: true,
    },
    "$store.state.filtre": {
      handler() {
        this.filtre = this.$store.state.filtre.filter_by;
        this.selitem = this.$store.state.filtre.obj;
        this.klist++;
      },
    },
  },
  created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.selitem = this.$route.params.obj;
      this.klist++;
    }
  },

  async mounted() {
    this.editer =
      (this.tiertype == 1 && this.$store.state.auth.includes("02080")) ||
      (this.tiertype == 2 && this.$store.state.auth.includes("02081")) ||
      this.$store.state.isadmin;

    this.valider =
      (this.tiertype == 1 && this.$store.state.auth.includes("03080")) ||
      (this.tiertype == 2 && this.$store.state.auth.includes("03081")) ||
      this.$store.state.isadmin;

    this.liberer =
      (this.tiertype == 1 && this.$store.state.auth.includes("04080")) ||
      (this.tiertype == 2 && this.$store.state.auth.includes("04081")) ||
      this.$store.state.isadmin;

    await this.get_data();
  },

  methods: {
    refresh() {
      this.klist++;
    },
    async requette(query, v, f) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: f,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    OpenDetailForm(item) {
      this.cautiondetail = item;
      this.dkey++;
      this.isDetailClosed = false;
    },
    closeDetailForm() {
      this.isDetailClosed = true;
    },
    async get_data() {
      this.$store.dispatch("Changed", true);
      this.headers[6].text = this.tiertype == 2 ? "Fournisseur" : "Client";
      this.headers[12].hiden = this.tiertype == 2 ? true : false;
      let v, r;
      let TypeScope = this.tiertype == 2 ? [2, 4] : [1, 3];
      let w = {
        AND: [
          { column: "CMD_TYPE", operator: "IN", value: TypeScope },
          { column: "STATUT_ID", value: 7 },
        ],
      };
      v = {
        where: w,
      };

      r = await this.requette(ACTUAL_CMDS, v, "no-cache");

      if (r) this.cmds_list = r.allcmds;

      r = await this.requette(DATA, v);
      if (r) {
        this.agences = r.agences;
        this.cautions_type = r.cautions_type;
        this.currencies = r.currencies;
      }
    },

    CautionChange(item) {
      this.aff++;
      this.caution = item;
      this.selitem = item;
    },
    OpenCautionForm(item) {
      this.kf++;
      this.caution = item;
      this.isCautionClosed = false;
    },
    closeCautionForm(item) {
      this.selitem = item;
      this.isCautionClosed = true;
    },
    CautionDelete() {
      this.aff++;
      this.caution = {};
      this.get_data();
    },
  },
};
</script>
